import { useAddToCart as swAddToCart } from "@shopware/composables";
import { useGtm } from "@gtm-support/vue-gtm";
import type { Schemas } from "#shopware";

export async function useAddToCart(product: Ref<Schemas["Product"]>) {
  const _addToCart = swAddToCart(product);
  const { pushSuccess, pushError } = useNotifications();
  const { getErrorsCodes } = useCartNotification();
  const { t } = useI18n();
  const { quantity } = _addToCart;
  const { productToTrackingItem } = await useTrackingHelper();
  const gtm = useGtm();

  async function addToCart() {
    await _addToCart.addToCart();

    const item = await productToTrackingItem(product.value);

    gtm?.push({ ecommerce: null });
    gtm?.push({
      event: "add_to_cart",
      ecommerce: {
        currency: useShopCurrency(),
        value: (item.price || 0) * quantity.value,
        items: [item],
      },
    });
  }

  const minPurchase = computed(() => product.value.minPurchase ?? 1); // TODO does this need changes if minPurchase is not but step is set? Can this even happen?
  const maxPurchase = computed(() => product.value.calculatedMaxPurchase);
  const purchaseStep = computed(() => product.value.purchaseSteps ?? 1);

  const addToCartWithNotifications = async () => {
    await addToCart();
    getErrorsCodes()?.forEach((element) => {
      // Deactivate error message for this case. See: SPW-365 Prüfung gültiger Zahlungsarten
      if (element.messageKey !== "payment-method-blocked") {
        pushError(t(`errors.${element.messageKey}`, { ...element }));
      }
    });
    pushSuccess(
      t("product.messages.addedToCart", { p: product.value.translated?.name }),
    );
  };

  function incrementPurchaseQuantity() {
    quantity.value += purchaseStep.value;
    if (quantity.value > maxPurchase.value) quantity.value = maxPurchase.value;
  }
  const canIncrementPurchaseQuantity = computed(
    () => quantity.value < maxPurchase.value,
  );

  function decrementPurchaseQuantity() {
    quantity.value -= purchaseStep.value;
    if (quantity.value < minPurchase.value) quantity.value = minPurchase.value;
  }
  const canDecrementPurchaseQuantity = computed(
    () => quantity.value > minPurchase.value,
  );

  return {
    ..._addToCart,
    addToCart,
    addToCartWithNotifications,
    minPurchase,
    maxPurchase,
    purchaseStep,
    incrementPurchaseQuantity,
    canIncrementPurchaseQuantity,
    decrementPurchaseQuantity,
    canDecrementPurchaseQuantity,
  };
}
